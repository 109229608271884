<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-vertical is-half">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Annotations</p>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">RR</label>
                <textarea class="textarea"
                  v-model="getAnnotation(remarkTypes.RR).remarks"
                  :rows="rows" />
              </div>
              <div class="field">
                <label class="label">REP</label>
                <textarea
                  class="textarea"
                  v-model="getAnnotation(remarkTypes.REP).remarks"
                  :rows="rows" />
              </div>
              <div class="field">
                <label class="label">FIBER</label>
                <textarea
                  class="textarea"
                  v-model="getAnnotation(remarkTypes.FIBER).remarks"
                  :rows="rows" />
              </div>
              <div class="field">
                <label class="label">CD</label>
                <textarea
                  class="textarea"
                  v-model="getAnnotation(remarkTypes.CD).remarks"
                  :rows="rows" />
              </div>
              <div class="field">
                <label class="label">PART</label>
                <textarea
                  class="textarea"
                  v-model="getAnnotation(remarkTypes.PART).remarks"
                  :rows="rows" />
              </div>
              <div class="field">
                <label class="label">MISC</label>
                <textarea
                  class="textarea"
                  v-model="getAnnotation(remarkTypes.MISC).remarks"
                  :rows="rows" />
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">RWA</label>
                <textarea
                  class="textarea"
                  v-model="getAnnotation(remarkTypes.RWA).remarks"
                  :rows="rows" />
              </div>
              <div class="field">
                <label class="label">PAINT</label>
                <textarea
                  class="textarea"
                  v-model="getAnnotation(remarkTypes.PAINT).remarks"
                  :rows="rows" />
              </div>
              <div class="field">
                <label class="label">CRUSH</label>
                <textarea
                  class="textarea"
                  v-model="getAnnotation(remarkTypes.CRUSH).remarks"
                  :rows="rows" />
              </div>
              <div class="field">
                <label class="label">MECH</label>
                <textarea
                  class="textarea"
                  v-model="getAnnotation(remarkTypes.MECH).remarks"
                  :rows="rows" />
              </div>
              <div class="field">
                <label class="label">OPG</label>
                <textarea
                  class="textarea"
                  v-model="getAnnotation(remarkTypes.OPG).remarks"
                  :rows="rows" />
              </div>
              <div class="field">
                <label class="label">SUBLET</label>
                <textarea
                  class="textarea"
                  v-model="getAnnotation(remarkTypes.SUBL).remarks"
                  :rows="rows" />
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import { RemarkTypes, EventHubTypes } from '@/enums'
import Guid from '@/components/Guid'

export default {
  name: 'QuoteAnnotations',
  filters: {},
  props: {
    value: null
  },
  data() {
    return {
      innerValue: null,
      rows: 2
    }
  },
  computed: {
    remarkTypes() {
      return RemarkTypes
    }
  },
  created() {
    this.innerValue = this.value
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = this.value
    })
  },
  methods: {
    getAnnotation(remarkType) {
      let annotation = this.innerValue.annotations.find(r => r.remarkTypeId === remarkType)
      if (!annotation) {
        annotation = {
          isAlert: false,
          isDeleted: false,
          isNew: true,
          remarkDateTime: new Date(),
          remarkId: Guid.newGuid(),
          remarkTypeId: remarkType,
          remarks: ''
        }
        this.innerValue.annotations.push(annotation)
      }
      return annotation
    }
  }
}
</script>
